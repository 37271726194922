// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-managers-tsx": () => import("./../../../src/pages/managers.tsx" /* webpackChunkName: "component---src-pages-managers-tsx" */),
  "component---src-pages-marketers-tsx": () => import("./../../../src/pages/marketers.tsx" /* webpackChunkName: "component---src-pages-marketers-tsx" */),
  "component---src-pages-policy-payment-tsx": () => import("./../../../src/pages/policy-payment.tsx" /* webpackChunkName: "component---src-pages-policy-payment-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-sales-tsx": () => import("./../../../src/pages/sales.tsx" /* webpackChunkName: "component---src-pages-sales-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

